import React from "react";

export default function AppLogo() {
	return (
		<div className='application-logo'>
			<h1 className='application-logo__title'>stay safe</h1>
			<h2 className='application-logo__subtitle'>interactive</h2>
			<h3 className='application-logo__draft'>draft</h3>
		</div>
	);
}
