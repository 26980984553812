import React from "react";
import coverWorker from "../../assets/img/illustrations/cover_worker.svg";
// import staySafeLogoHorizontal from "../../assets/img/logos/stay-safe-logo-horizontal.svg";
import staySafeLogoHorizontal from "../../assets/img/logos/stay-safe-beta-logo-horizontal.svg";
import logoLhoist from "../../assets/img/logos/logo-lhoist.svg";

import { Link } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
export default function Footer() {
	const { screensTranslations } = useUser();

	if (!screensTranslations || !screensTranslations.home) return <p>loading</p>;
	const footerTranslations = screensTranslations.footer;
	const pagesNameTranslations = screensTranslations.pagesName;

	return (
		<>
			<Link
				to={"/Home"}
				className='cta cta--construction cta--round cta--button-icon cta--home'></Link>
			<footer className='footer'>
				<img className='footer__cover_worker' src={coverWorker} alt='' />
				<img className='footer__app-horizontal-logo' src={staySafeLogoHorizontal} alt='' />

				<div className='footer__colophon'>
					<a href='https://www.lhoist.com/en' target='_blank' rel='noreferrer'>
						<img className='logo-lhoist' src={logoLhoist} alt='' />
					</a>
					<div className='credits'>
						<p>
							<span className='year'>
								<a
									className='casual-link'
									href='https://www.lhoist.com/en'
									target='_blank'
									rel='noreferrer'>
									© 2024 Lhoist
								</a>
							</span>{" "}
							{footerTranslations?.copyright}
						</p>
						<p> {footerTranslations?.all_rights_reserved}</p>
					</div>

					<ul className='footer__links'>
						<li>
							<Link to='/conditions'>
								{pagesNameTranslations?.conditions}
							</Link>
						</li>
						<li>
							<Link to='/cookies'>{pagesNameTranslations?.cookies}</Link>
						</li>
						<li>
							<Link to='/confidentiality'>
								{pagesNameTranslations?.confidentiality}
							</Link>
						</li>
						<li className='made_by'>
							Web & graphic design by{" "}
							<a
								target='_blank'
								rel='noreferrer'
								href='https://deligraph.com/'>
								Deligraph
							</a>
						</li>
					</ul>
				</div>
			</footer>
		</>
	);
}
