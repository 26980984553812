import { useUser } from "../hooks/useUser";
import { Link } from "react-router-dom";

import Nav from "../components/ui/Nav";
import coverWorker from "../assets/img/illustrations/cover_worker.svg";
import screenWorker1 from "../assets/img/illustrations/lhoist-accueil-personnage-femme.svg";
import screenWorker2 from "../assets/img/illustrations/lhoist-accueil-personnage-homme.svg";
import AnimatedPage from "../components/AnimatedPage";
import BrandingLogo from "../components/ui/LhoistBrandingLogo.jsx";

import CookiesModal from "../components/Modals/CookiesModal.jsx";

export default function Home() {
	const { screensTranslations } = useUser();

	if (!screensTranslations || !screensTranslations.home) return <p>loading</p>;
	const currentScreenTranslations = screensTranslations.home;
	const uiTranslations = screensTranslations.ui;

	return (
		<>
			{/* <Clouds /> */}
			<CookiesModal />
			<AnimatedPage className='page-container page-container--home'>
				<div className='screen screen--home'>
					<Nav />
					<BrandingLogo />
					<section className='screen__content'>
						<img className='cover_worker' src={coverWorker} alt='' />
						<div className='screen__content__wrapper'>
							<h1 className='application_title'>stay safe</h1>
							<h2 className='application_subtitle'>interactive</h2>
							<h3 className='application_draft'>draft</h3>
							<p className='application_description'>
								{currentScreenTranslations.app_description}
							</p>
						</div>
						<Link
							title={uiTranslations.start}
							to='/select-profile'
							className='cta cta--construction cta--round cta--button-icon cta--play  '
						/>
					</section>
					<img className='screen_worker_1' src={screenWorker1} alt='' />
					<img className='screen_worker_2' src={screenWorker2} alt='' />
				</div>
			</AnimatedPage>
		</>
	);
}
